<template>
  <!-- Desc: View: product & category targeting for - Prouct targeting adgroups  -->
  <div
    class="adGroupKeywordNegativeTargeting card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <component
      :is="addNegativeTargetCustomActionPanelConfigs.ui_component"
      v-if="showAddNegativeTargetingPanel"
      v-bind="addNegativeTargetCustomActionPanelConfigs.props"
      v-on="addNegativeTargetCustomActionPanelConfigs.events"
    />
    <CustomRecommendations
      v-else-if="
        Object.keys(recommendationPropsAndEvents.props.metadata).length !== 0
      "
      v-bind="recommendationPropsAndEvents.props"
      v-on="recommendationPropsAndEvents.events"
    />
    <div
      v-if="selections.length"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <dashboard-inflator
      class="u-width-100 adgroups_negative_targeting"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      :disable-envelop-width-class="true"
      @metadataUpdated="metadataUpdated"
      @widgetParamsChanged="widgetParamsChanged"
      @widgetParamsCollectionUpdated="widgetParamsCollectionUpdated"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import CustomRecommendations from '@/pages/dashboard_service_campaign_management/common_components/custom-recommendation.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import {
  deepReplaceObjectValues,
  findDimensionValueFromWidgetParams
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';

const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');

const commonRequestParams = (vueRef) => ({
  adgroup_sp_tab_negativekeywordtargeting: {
    ':widget': 'adgroup_sp_tab_negativekeywordtargeting',
    ':orderByList': [],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  },
  adgroup_sp_tab_negativekeyword_recommendation: {
    ':widget': 'adgroup_sp_tab_negativekeyword_recommendation',
    ':orderByList': [],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  },
  adgroup_sp_tab_negativeproducttargeting: {
    ':widget': 'adgroup_sp_tab_negativeproducttargeting',
    ':orderByList': [],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  }
});

export default {
  components: {
    tableWrapper,
    actionsBar,
    radioSelectionWithInput,
    footerButtons,
    CustomRecommendations
  },
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const pageInfo = {
      name: this.tabMetadata.page
    };
    const context = this;
    return {
      pageInfo,
      defaultSearchText: '',
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        }
      },
      recFilter: '',
      recCustomComponent:
        this.entityConfig.tabs[this?.tabMetadata?.component]
          ?.recCustomComponent,
      showRecCustomComponent: false,
      preferNoRecFilterList: [
        'RECOMMENDATIONS_NEGATIVEKEYWORDS_INEFFICIENT_SEARCHTERMS'
      ],
      selectedRecommendation: {},
      addNegativeTargetCustomActionPanelConfigs: null,
      isCustomActionStateVisible: false,
      targetingType: null,
      selections: [],
      stateChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      actionIdMethodMap: {
        makeArchive: this.makeArchive,
        makeTargetArchive: this.makeTargetArchive
      },
      widget: {},
      allowedActions: null,
      tabConfig: null,
      recommendationWidgetParams: {},
      recommendationMetadata: {},
      showAddNegativeTargetingPanel: false
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    recommendationPropsAndEvents() {
      return {
        props: {
          footerOptions: [],
          showLastUpdatedDate: false,
          entityName: 'keyword',
          headingName: 'RECOMMENDATIONS TO ADD/ARCHIVE NEGATIVE KEYWORDS',
          countKey: 'ams_cm_recommendation_count',
          labelKey: 'ams_cm_recommendation_type',
          recCustomComponent:
            (this.showRecCustomComponent &&
              this.recCustomComponent(this)[this?.recFilter?.value]) ||
            {},
          selectedRecommendation: this.selectedRecommendation,
          isCustomActionStateVisible: this.isCustomActionStateVisible,
          widgetRequestParams: this.recommendationWidgetParams,
          metadata: this.recommendationMetadata
        },
        events: {
          recommendationFilter: this.recommendationFilter,
          handleRecommendation: this.handleRecommendation
        }
      };
    },
    getAdGroupInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getter];
    },
    headerOptions() {
      const that = this;
      const obj = {};
      obj.adgroup_sp_tab_negativekeywordtargeting = {
        props: {
          headerOptions: [
            {
              ui_component: 'rb-button',
              props: {
                clickFn: that.addNegativeTargetingBtnClickFn,
                text: 'Add Custom Negative Keyword',
                type: 'filled'
              },
              class: 'u-spacing-mr-xl'
            }
          ],
          enableDownload: false,
          enableManageColumns: !this.selections.length,
          manageColumnGridPosition: 4,
          headerContainerGridStyles: {
            'grid-template-columns': 'auto 1fr auto',
            display: this.selections.length ? 'none' : 'grid',
            'align-items': 'center'
          },
          searchParams: {
            show: true,
            searchDimension: 'ams_cm_negativetargets_keyword_text',
            placeholder: 'Find a Negative Keyword',
            value: that.defaultSearchText
          },
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: this.handleSelectionChange
          },
          rowHeight: 45
        }
      };
      obj.adgroup_sp_tab_negativeproducttargeting = {
        props: {
          headerOptions: [
            {
              ui_component: 'rb-button',
              props: {
                clickFn: that.addNegativeTargetingBtnClickFn,
                text: 'Add Custom Negative Products',
                type: 'filled'
              },
              class: 'u-spacing-mr-xl'
            }
          ],
          enableDownload: false,
          enableManageColumns: !this.selections.length,
          manageColumnGridPosition: 4,
          headerContainerGridStyles: {
            display: this.selections.length ? 'none' : 'flex',
            'align-items': 'center'
          },
          searchParams: {
            show: true,
            searchDimension: 'ams_cm_negativetargets_keyword_text',
            placeholder: 'Find a Negative Target',
            value: that.defaultSearchText
          },
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: this.handleSelectionChange
          },
          rowHeight: 45
        }
      };
      if (this.tabConfig?.getHeaderOptions) {
        obj[this.tabMetadata?.widgets[0]] =
          this.tabConfig?.getHeaderOptions(that);
      }

      return obj;
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        const initRequestParams = {
          ...this.initRequestParams,
          ...commonRequestParams(this)
        };
        this.updateEntireRequestParams(initRequestParams);
      },
      immediate: true,
      deep: true
    },
    getAdGroupInfo(newVal) {
      if (newVal?.data) {
        this.addNegativeTargetCustomActionPanelConfigs =
          this.tabConfig[this.targetingType]?.customActionPanelComponent(this);
      }
    },
    tableDataComponentKey: {
      handler(newVal) {
        const globalRequestParams = this.getGlobalRequestParams();
        this.updateGlobalRequestParams({ ...globalRequestParams, key: newVal });
      }
    }
  },
  created() {
    Vue.component(this.tabMetadata?.widgets[0], dashboardServiceTable);
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;

    if (this.entityConfig?.getWidget) {
      const widgetFromConfig = this.entityConfig?.getWidget(
        this.entityId,
        this.tabMetadata,
        this.parentEntityId
      );
      this.initRequestParams[this.tabMetadata.widgets[0]] = widgetFromConfig;
    }
    const initRequestParams = {
      ...this.initRequestParams,
      ...commonRequestParams(this)
    };
    this.updateEntireRequestParams(initRequestParams);
    this.targetingType = this.tabConfig?.isProductAdgroup
      ? 'negativeTarget'
      : 'negativeKeyword';
    this.stateChangeRequestPayload =
      tabConfig[this.targetingType]?.stateChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.allowedActions = tabConfig[this.targetingType]?.allowedActions;
    this.$store.dispatch(this.tabConfig?.apiConfigs?.fetchAction, {
      entityId: this.entityId,
      entityType: this.entityConfig.entityType
    });
  },
  methods: {
    widgetParamsChanged(value) {
      const tab = this.tabConfig?.isProductAdgroup
        ? 'negativeTarget'
        : 'negativeKeyword';
      const tabTypeMap = {
        negativeKeyword: 'adgroup_sp_tab_negativekeywordtargeting',
        negativeTarget: 'adgroup_sp_tab_negativeproducttargeting'
      };
      const searchText = findDimensionValueFromWidgetParams(
        value,
        tabTypeMap?.[tab],
        'ams_cm_negativetargets_keyword_text'
      );
      this.defaultSearchText = searchText?.dimensionValue || '';
    },
    widgetParamsCollectionUpdated(paramsCollection) {
      if (paramsCollection?.adgroup_sp_tab_negativekeyword_recommendation) {
        this.recommendationWidgetParams =
          paramsCollection?.adgroup_sp_tab_negativekeyword_recommendation;
      }
    },
    metadataUpdated(data) {
      let recommendationMetadata = {};
      data.forEach((item) => {
        if (item.name === 'adgroup_sp_tab_negativekeyword_recommendation') {
          recommendationMetadata = item;
        }
      });
      this.recommendationMetadata = recommendationMetadata;
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      this.actionIdMethodMap[actionId]();
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.showAddNegativeTargetingPanel = false;
    },
    makeArchive(state = 'archived') {
      const payloadArr = [];
      for (const selection of this.selections) {
        if (selection?.data?.ams_cm_adgroup_status === state) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_negativetargets_state,
            ':state': state,
            ':pageUrl': window.location.href,
            ':keywordId': selection?.data?.keyword_id,
            ':keywordText':
              selection.data.ams_cm_negativetargets_keyword_text || 'NA',
            ':adgroupName': this.getAdGroupInfo?.data?.name || 'NA',
            ':matchType':
              selection?.data?.ams_cm_negativetargets_match_type || 'NA'
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        const entityName = {
          singular: 'Keyword Status',
          plural: 'Keyword Statuses',
          stopFetchApi: true
        };
        this.updateMetadataAction(payloadArr, entityName);
      } else {
        this.closeActionBar();
      }
    },
    makeTargetArchive(state = 'archived') {
      const payloadArr = [];
      for (const selection of this.selections) {
        if (selection?.data?.ams_cm_adgroup_status === state) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_negativetargets_state,
            ':state': state,
            ':pageUrl': window.location.href,
            ':keywordId': selection?.data?.keyword_id,
            ':keywordText':
              selection.data.ams_cm_negativetargets_keyword_text || 'NA',
            ':adgroupName': this.getAdGroupInfo?.data?.name || 'NA',
            ':matchType':
              selection?.data?.ams_cm_negativetargets_match_type || 'NA'
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        const entityName = {
          singular: 'Keyword Status',
          plural: 'Keyword Statuses',
          stopFetchApi: true
        };
        this.updateMetadataAction(payloadArr, entityName);
      } else {
        this.closeActionBar();
      }
    },
    callAddNegativeTargets(selections) {
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(
          this.tabConfig[this.targetingType].addNegativeTargetRequestPayload
        );
        const expressionType = selection?.categoryId
          ? 'asinCategorySameAs'
          : 'asinSameAs';
        const target = selection?.categoryId
          ? selection?.categoryId
          : selection?.competitor_asin;
        const targetView = selection.categoryId
          ? selection?.category
          : selection?.competitor_asin;
        const reqValues = {
          ':campaignId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':pageUrl': window.location.href,
          ':newKeywordText':
            this.targetingType === 'negativeTarget'
              ? target
              : selection.recommended_keyword,
          ':matchType':
            this.targetingType === 'negativeTarget'
              ? 'TARGETING_EXPRESSION'
              : selection.matchTypeToShow,
          ':adgroupName': this.tabMetadata?.adgroup_name,
          ':viewPayloadKeywordText':
            this.targetingType === 'negativeTarget'
              ? targetView
              : selection.recommended_keyword,
          ':expressionType': expressionType // asinSameAs, asinCategorySameAs
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Negative Target',
          plural: 'Negative Targets',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: true,
        entityName
      });
      this.closeActionBar();
    },
    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    handleRecommendation(val) {
      this.selectedRecommendation = val;
    },
    // event listener to capture the action state in actions bar
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    },
    recommendationFilter(val, label) {
      if (val) {
        const [value] = Object.values(val);
        this.recFilter = { value, label };
        if (this.preferNoRecFilterList.includes(value)) {
          this.toggleRecCustomComponent(true);
        }
      } else {
        this.recFilter = '';
      }
    },
    addNegativeTargetingBtnClickFn() {
      this.closeActionBar();
      this.showAddNegativeTargetingPanel = true;
    },
    reloadComponent(boolReload = true) {
      if (boolReload) {
        this.$store.dispatch(
          this.tabConfig?.apiConfigs?.fetchTableDataComponentKeyGetter
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.adGroupKeywordNegativeTargeting {
  -webkit-box-shadow: 0 0px 0px 0 #e9eaeb;
  box-shadow: 0 0px 0px 0 #e9eaeb;
  background: #fff;
}
.adGroupKeywordNegativeTargeting
  .recommendations-container
  .header-container-cwc {
  padding: 0px;
}
.adGroupKeywordNegativeTargeting
  .recommendations-container
  .custom_widget_body
  .header
  .custom-action-panel
  .custom-entity
  .split-table-section
  .action-header {
  padding-top: 0;
}
.adGroupKeywordNegativeTargeting
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after {
  content: 'recommendations';
  font-family: 'ProximaNova', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.4rem;
  margin-top: 0.5rem;
}
.adGroupKeywordNegativeTargeting .order-1 {
  order: 1;
}
.adGroupKeywordNegativeTargeting .order-2 {
  order: 2;
}
.adGroupKeywordNegativeTargeting .order-3 {
  order: 3;
}
.adGroupKeywordNegativeTargeting .order-4 {
  order: 4;
}
.adgroups_negative_targeting .dashboard-service-table-wrapper,
.adgroups_negative_targeting .recommendations-container {
  margin: 0;
  padding-bottom: 0;
}
.adgroups_negative_targeting
  .dashboard-service-table-wrapper
  .custom_widget_container.card,
.adgroups_negative_targeting
  .recommendations-container
  .custom_widget_container.card {
  border-top: none;
  box-shadow: none;
}
.adgroups_negative_targeting
  .dashboard-service-table-wrapper
  .custom_widget_body,
.adgroups_negative_targeting .recommendations-container .custom_widget_body {
  padding-right: 0;
  padding-left: 0;
}
.adgroups_negative_targeting .dashboard-service-table-wrapper .rec-heading,
.adgroups_negative_targeting .recommendations-container .rec-heading {
  margin-top: -0.8rem;
  margin-left: 0.8rem;
}
</style>
